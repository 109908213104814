import React from "react";
import CustomPopup2 from "../../../components/Popup/components/CustomPopup2";
import styled from "styled-components";

const VisionVideoPopupStyle = styled.div``;
const VisionVideoPopup4 = (props) => {
  return (
    <VisionVideoPopupStyle>
      <CustomPopup2
        type="video"
        youtube_id="rkjQetG6LZA"
        show={props.show}
        onClose={props.onClose}
      ></CustomPopup2>
    </VisionVideoPopupStyle>
  );
};

export default VisionVideoPopup4;
