import styled from "styled-components";

export const WR = styled.div`
  .hovered & {
  }
  position: relative;
  .tn {
    position: absolute;
    top: 7px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    /* background-color: rgba(200, 20, 20, 0.3); */
    z-index: 99999999;
    /* opacity: 0.5; */
  }
  .tdd {
    position: absolute;
    top: 0;
    width: 35px;
    height: 100%;
    /* background-color: rgba(200, 20, 20, 0.3); */
    z-index: 99999999;
    /* opacity: 0.5; */
    left: 0;
    right: auto;
    width: calc(100% - 35px);
    z-index: 333;
    z-index: 0;
  }
  .hovered & {
    z-index: 9999999999;

    .tn {
      /* width: 100%; */
    }
    .tdd {
      width: 100% !important;
      height: 200% !important;
      top: -20px !important;
      right: 0;
      left: auto;
      width: calc(100% + 35px);
      z-index: 9999999999;
    }
  }
`;

export const StyledButton = styled.a`
  display: inline-block;
  width: auto;
  position: relative;
  transition: 0.2s;
  font-family: "KBFG Text";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: #000;
  text-decoration: none;
  background: none;
  cursor: pointer;

  ${(props) => {
    var out = ``;
    switch (props.type) {
      case "primary": // primary 버튼일때
        out += `
                    border: none;
                    line-height:35px;
                    height:35px;
                    span {
                        position:relative;
                        z-index:2;
                    }
                    i {
                        display:inline-block;
                        vertical-align:middle;
                        margin-left: 8px;
                        border-radius: 50%;
                        width: 35px;
                        height: 35px;
                        trasition: .3s;
                        :after {
                            display: inline-block;
                            content: "";
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            background-image: url(${
                              props.theme.image_url
                            }/common/icon-${props.icon}.svg);
                            background-size: ${
                              props.iconSize ? props.iconSize : "18px 18px"
                            };
                            background-repeat: no-repeat;
                            background-position: center center ;
                            width: 35px;
                            height: 35px;
                        }
                        :before {
                            content: '';
                            right: 0px;
                            top: 0px;
                            display: block;
                            position: absolute;
                            width: 35px;
                            height:35px;
                            background: #f7f7f7;
                            border-radius: 50%;
                            transition:.3s;
                        }
                    }
                    .load-in-view:hover &,
                    .section:hover &,
                    div:hover > & {
                        color:#000;
                        i {
                            :before {
                                // top: -3px;
                                display: block;
                                width: 120%;
                                // height:40px;
                                background: #FFBC00;
                                border-radius: 50px;
                            }
                        }
                    }
                    .load-in-view:hover &:hover,
                    .section:hover &:hover,
                    div:hover > &:hover
                    &:hover {
                        color:#000;
                        i {
                            :before {
                                // top: -3px;
                                display: block;
                                width: 120%;
                                // height:40px;
                                background: #f7f7f7;
                                border-radius: 50px;
                            }
                        }
                    }

                    @media ${(props) => props.theme.tablet} {
                        font-size: 15px;
                    }
                `;
        if (props.color === "white")
          out += `
                    color: #fff; 
                    i{
                        :before {
                            background: #fff;
                        }
                    }
                 `;
        if (props.color === "bg-white")
          out += `
                    color: #000;
                    i{
                        :before {
                            background: #fff;
                        }
                    }
                    .section:hover &,
                    div:hover > &,
                    &:hover {
                        color:#000;
                        i {
                            :before {
                                background: #fff;
                            }
                        }
                    }
                 `;
        break;
      case "unique": // unique 버튼일때
        out += `
                    border: none;
                    line-height:35px;
                    height:35px;
                    span {
                        position:relative;
                        z-index:2;
                    }
                    i {
                        display:inline-block;
                        vertical-align:middle;
                        margin-left: 8px;
                        border-radius: 50%;
                        width: 35px;
                        height: 35px;
                        trasition: .3s;
                        :after {
                            display: inline-block;
                            content: "";
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            background-image: url(${
                              props.theme.image_url
                            }/common/icon-${props.icon}.svg);
                            background-size: ${
                              props.iconSize ? props.iconSize : "18px 18px"
                            };
                            background-repeat: no-repeat;
                            background-position: center center ;
                            width: 35px;
                            height: 35px;
                        }
                        :before {
                            content: '';
                            right: 0px;
                            top: 0px;
                            display: block;
                            position: absolute;
                            width: 35px;
                            height:35px;
                            background: #f7f7f7;
                            border-radius: 50%;
                            transition:.3s;
                        }
                    }
                    .load-in-view:hover &,
                    .section:hover &,
                    div:hover > & {
                        color:#000;
                        i {
                            :before {
                                // top: -3px;
                                display: block;
                                width: 120%;
                                // height:40px;
                                background: #FFBC00;
                                border-radius: 50px;
                            }
                        }
                    }
                    .load-in-view:hover &:hover,
                    .section:hover &:hover,
                    div:hover > &:hover
                    &:hover {
                        color:#000;
                        i {
                            :before {
                                // top: -3px;
                                display: block;
                                width: 120%;
                                // height:40px;
                                background: #f7f7f7;
                                border-radius: 50px;
                            }
                        }
                    }

                    @media ${(props) => props.theme.tablet} {
                        font-size: 15px;
                    }
                    @media(max-width: 770px) {
                        font-size: 13px;
                        position: absolute !important;
                        top: 166px !important;
                        right: 16px;
                        
                     }
                `;
        if (props.color === "white")
          out += `
                    color: #fff; 
                    i{
                        :before {
                            background: #fff;
                        }
                    }
                 `;
        if (props.color === "bg-white")
          out += `
                    color: #000;
                    i{
                        :before {
                            background: #fff;
                        }
                    }
                    .section:hover &,
                    div:hover > &,
                    &:hover {
                        color:#000;
                        i {
                            :before {
                                background: #fff;
                            }
                        }
                    }
                 `;
        break;
      case "header":
        out += `
                    border: none;
                    line-height:35px;
                    height:35px;
                    // width: 35px;
                    ul{
                        vertical-align: middle;
                    }
                    span {
                        position:relative;
                        z-index:2;
                    }
                    i {
                        display:inline-block;
                        vertical-align:middle;
                        margin-left: -13px;
                        border-radius: 50%;
                        width: 35px;
                        height: 35px;
                        svg{
                            position: relative;
                            top: 5px;
                            right: -1px;
                            transition: 0.4s;
                        }
                        path{
                            transition: 0.4s;
                        }

                        :after {
                            transition: 0.4s;
                            display: inline-block;
                            content: "";
                            position: absolute;
                            right: 0px;
                            top: 0px;
                          
                            background-size: ${
                              props.iconSize ? props.iconSize : "18px 18px"
                            };
                            background-repeat: no-repeat;
                            background-position: center center ;
                            width: 35px;
                            height: 35px;
                        }
                        :before {
                            content: '';
                            right: 0px;
                            top: 0px;
                            display: block;
                            position: absolute;
                            z-index: -1;
                            width: 35px;
                            height:35px;
                            background: #f7f7f7;
                            border-radius: 50%;
                            transition: 0.3s;
                        }
                    }
                    .headerwrapper:hover &,
                    &:hover{
                        i{
                        }
                    }

                    // .hovered & {
                    //     background #000 !important;
                    // }

                    .hovered & {
                        svg{
                            transition-delay: 0s;
                            transform: rotate(-135deg);
                        }
                        path{
                            transition-delay: 0s;
                            stroke: #FFBC00;
                        }
                    }
                   
                    // & :hover{
                    //     svg{
                    //         transition-delay: 0s;
                    //         transform: rotate(-135deg);
                    //     }
                    //     path{
                    //         transition-delay: 0s;
                    //         stroke: #FFBC00;
                    //     }
                    // }
                    .headerwrapper:not(:hover) &,
                    :not(:hover){
                        svg{
                                transition-delay: 0.3s;
                                transform: rotate(0);
                                path{
                                    transition: 0.4s;
                                }
                        }
                    }
                    :not(:hover){
                        i{
                            :before{
                                transition-delay: 0s;
                            }
                        }
                    }
                   i:hover {
                        :before {
                            // top: -3px;
                            transition-delay: 0.3s;
                            display: block;
                            width: 120%;
                            // height:40px;
                            background: #F7F7F7;
                            border-radius: 50px;
                        }
                        
                   }
                    .hovered & {

                        i {
                        }
                        i:before {
                            // top: -3px;
                            display: block;
                            width: 110%;
                            // height:40px;
                            background: #f7f7f7;
                            border-radius: 50px;
                        }
                    }

                    @media ${(props) => props.theme.tablet} {
                        font-size: 15px;
                        i:hover {
                            :before {
                                width: 110%;
                            }
                        }
                    }
                `;
        if (props.color === "white")
          out += `
                    color: #fff; 
                    i{
                        :before {
                            background: #fff;
                        }
                    }
                 `;
        if (props.color === "bg-white")
          out += `
                    color: #000;
                    i{
                        :before {
                            background: #fff;
                        }
                    }
                    .section:hover &,
                    div:hover > &,
                    &:hover {
                        color:#000;
                        i {
                            :before {
                                background: #fff;
                            }
                        }
                    }
                 `;
        break;
      case "secondary": // secondary 버튼일때
        out += `
                    position: relative;
                    color: #000 !important;
                    background: #FFFFFF;
                    border: none;
                    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
                    border-radius: 40px;
                    padding: 20px 55px;
                    width: 200px;
                    height: 60px;
                    transition: .3s;
                    :after {
                        position: absolute;
                        display: inline-block;
                        content: "";
                        left: 0px;
                        top: 0px;
                        width: 300px;
                        height: 60px;
                        background-image: url(${props.theme.image_url}/common/icon-${props.icon}.svg);
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    :hover{
                        opacity: .7;
                    }
                `;
        break;
      case "icon": // icon paly 버튼일때
        out += `
                    position: relative;
                    vertical-align: top;
                    border-radius: 50%;
                    background: #fff;
                    width:${props.size ? props.size : "70px"};
                    height:${props.size ? props.size : "70px"};
                    :after {
                        display: inline-block;
                        content: "";
                        left: 0px;
                        top: 0px;
                        width: ${props.size ? props.size : "70px"};
                        height: ${props.size ? props.size : "70px"};
                        background-image: url(${
                          props.theme.image_url
                        }/common/icon-${props.icon}.svg);
                        background-size: 25px;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }     
                `;
        if (props.disable)
          out += `color:#ccc;
                            border-color:#eee;
                            &:hover {
                                color:#ccc;
                                border-color:#eee;
                                cursor:not-allowed;
                            }
                            `;
        break;
      case "round":
        out += `
                    position: relative;
                    vertical-align: top;
                    border-radius: 50%;
                    background: #fff;
                    width:50px;
                    height:50px;
                    text-align:center;
                    line-height:50px;
                `;
        if (props.disable)
          out += `color:#ccc;
                            border-color:#eee;
                            &:hover {
                                color:#ccc;
                                border-color:#eee;
                                cursor:not-allowed;
                            }
                            `;
        break;

      default:
    }
    return out;
  }}
`;
