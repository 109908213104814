import React, { useRef, useEffect } from "react";
import { Player } from "video-react";
import { PopupWrapper, PopupContainer, Overlay, PopupInner } from "./styled";
import YouTube from "react-youtube";

const CustomPopup2 = (props) => {
  const player = useRef(null);
  // console.log(player);

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
      mute: 1,
      //   loop: 1,
      //   playlist: 'cAK3r7kPje0',
      controls: 1,
    },
  };

  useEffect(() => {
    if (props.show) {
      if (player.current) {
        player.current.play();
      }
    } else {
      if (player.current) {
        player.current.pause();
      }
    }
  }, [props.show]);

  return (
    <PopupWrapper type={props.type} show={props.show} top={props.top}>
      <Overlay show={props.show} onClick={props.onClose} />
      <PopupContainer>
        <PopupInner type={props.type} show={props.show} width={props.width}>
          <a onClick={props.onClose}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path id="ic_close_24px" d="M23,6.813,21.187,5,14,12.187,6.813,5,5,6.813,12.187,14,5,21.187,6.813,23,14,15.813,21.187,23,23,21.187,15.813,14Z" transform="translate(-5 -5)"/></svg> */}
            <div></div>
            <div></div>
          </a>
          <video autoPlay controls muted ref={player}>
            <source
              src="https://wp.daitso.kbds.co.kr/wp-content/uploads/2024/11/My-Name-is-Deep-Learning-Man_Upload.mp4"
              type="video/mp4"
            />
          </video>
        </PopupInner>
      </PopupContainer>
    </PopupWrapper>
  );
};
export default CustomPopup2;
