import React from "react";
import CustomPopup from "../../../components/Popup/components/CustomPopup";
import styled from "styled-components";

const VisionVideoPopupStyle = styled.div``;
const VisionVideoPopup2 = (props) => {
  return (
    <VisionVideoPopupStyle>
      <CustomPopup
        type="video"
        youtube_id="rkjQetG6LZA"
        show={props.show}
        onClose={props.onClose}
      ></CustomPopup>
    </VisionVideoPopupStyle>
  );
};

export default VisionVideoPopup2;
